import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Checkbox,
  Container,
  Button,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import {useStyles} from '../../style';
import {Fragment, useEffect, useRef, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {Stack, styled} from '@mui/system';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
  fetchInvoiceFormDataCreator,
  fetchInvoiceProductApproveCreator,
  fetchOrderFormApprovalDataCreator,
  fetchOrderFormProductDataCreator,
  fetchOrderInnerFormDataCreator,
} from '../../../redux-api/slice';
import {strings} from '@app/common/strings';
import {ToastType} from '@app/services/toaster';
import {ToasterService} from '@app/services';
import dateUtils from '@app/screens/zho/utils/dateFormat';
import OrderProductDetails from '../../../Order/components/orderProductDetails';
import {setStatusToggle} from '@app/screens/zho/pages/fc-admin/redux/tabName';
import {
  setCurrentOrderId,
  updateRequestPayloadOrder,
} from '../../../redux/orderStore';

interface CheckBoxState {
  id: number;
  checked: boolean;
}

export const InvoiceFormTable = ({
  handleToggleModal,
  setOrderFormId,
  userLoginInfo,
}: any) => {
  const classes = useStyles();
  const {DateFormat} = dateUtils;
  const [openCompetitor, setOpenCompetitor] = useState({id: 0, open: false});
  const [orderIds, setOrderIds] = useState<number[]>([]);
  const [openTooltipId, setOpenTooltipId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const orderId = useRef('');
  const dispatch = useDispatch();

  const tableData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormData,
  );

  const statusToggleNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const orderFormPayload = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.requestPayloadOrder,
  );

  const orderFormDataLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormLoading,
  );

  const orderInnerFormData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderInnerFormData,
  );

  const orderInnerFromLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderInnerFormLoading,
  );

  const ApprovalOrderComplete = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderProductApprovalComplete,
  );

  const [checkBox, setCheckBox] = useState<CheckBoxState[]>([]);

  const toggleCheckBox = (type: any, id: number) => {
    setCheckBox((prevCheckBox: any) => {
      let updatedorderIds: number[] = [...orderIds];

      if (type === 'all') {
        const allChecked = prevCheckBox.every(
          (item: any) => item.checked === true,
        );
        const newCheckBoxState = prevCheckBox.map((item: any) => ({
          ...item,
          checked: !allChecked,
        }));

        // Update orderIds based on allChecked status
        updatedorderIds = allChecked
          ? []
          : tableData.orderList?.map((orderData: any) => orderData.orderId) ||
            [];

        setOrderIds(updatedorderIds);
        return newCheckBoxState;
      } else {
        const newCheckBoxState = prevCheckBox.map((item: any) => {
          if (item.id === type) {
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return item;
        });

        // Update orderIds based on individual checkbox state
        const isChecked = newCheckBoxState.find(
          (item: any) => item.id === type,
        )?.checked;
        if (isChecked) {
          updatedorderIds.push(id);
        } else {
          updatedorderIds = updatedorderIds.filter(orderId => orderId !== id);
        }

        setOrderIds(updatedorderIds);
        return newCheckBoxState;
      }
    });
  };
  const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      fontSize: 11.7,
      border: '1px solid #dcdce0',
      borderRadius: '5px',
      padding: '0.3rem',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  }));

  const getStatusColor = (status: number) => {
    if (status === 0) {
      return '#ff7650';
    } else if (status === 1) {
      return '#01a685';
    } else if (status === 3 || status === 4) {
      return '#f45b60';
    } else if (status === 5) {
      return '#34b53a';
    }
  };

  const isRowHighlighted = (index: number, tableData: any): boolean => {
    return tableData?.orderList[index]?.isHighlighted || false;
  };

  const handleInnerClickData = (id: number, index: number) => {
    setOpenCompetitor({
      id: index,
      open: true,
    });
    dispatch(fetchOrderInnerFormDataCreator(id));
  };

  const handleApprovalInvoiceForm = () => {
    if (orderIds.length === 0) {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Please select a order to Approve!',
        ToastType.ERROR,
      );
    }
    if (orderIds.length > 0) {
      dispatch(
        fetchInvoiceProductApproveCreator(
          userLoginInfo?.staffPositionId,
          userLoginInfo?.userId,
          orderIds,
        ),
      );
    }
  };

  useEffect(() => {
    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
      }),
    );

    dispatch(
      fetchInvoiceFormDataCreator(
        {
          ...orderFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          pageSize: rowsPerPage,
        },
        1,
      ),
    );

    if (tableData?.orderList) {
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }

    dispatch(setStatusToggle(1));
  }, []);

  useEffect(() => {
    if (tableData?.orderList) {
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }
  }, [tableData]);

  useEffect(() => {
    dispatch(
      fetchInvoiceFormDataCreator(
        {
          ...orderFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
        },
        1,
      ),
    );

    if (
      orderId.current !== undefined &&
      orderId.current !== null &&
      orderId.current !== ''
    ) {
      dispatch(fetchOrderFormProductDataCreator(orderId.current));
      dispatch(fetchOrderFormApprovalDataCreator(orderId.current));
    }

    if (tableData?.orderList) {
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }
    setOrderIds([]);
  }, [ApprovalOrderComplete]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    setOrderIds([]);
    setOpenCompetitor({id: 0, open: false});
  }, [statusToggleNo]);

  const handleOpenReviewPopup = (orderId: number) => {
    handleToggleModal();
    dispatch(fetchOrderFormProductDataCreator(orderId));
    dispatch(fetchOrderFormApprovalDataCreator(orderId));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);

    dispatch(
      fetchInvoiceFormDataCreator(
        {
          ...orderFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          pageNumber: newPage,
          pageSize: rowsPerPage,
        },
        statusToggleNo,
      ),
    );

    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: rowsPerPage,
        pageNumber: newPage, // API expects one-based page number
      }),
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setPage(0); // Reset to the first page (zero-based for Material-UI)
    dispatch(
      fetchInvoiceFormDataCreator(
        {
          ...orderFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          pageNumber: 0,
          pageSize: newPageSize,
        },
        statusToggleNo,
      ),
    );

    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: newPageSize,
        pageNumber: 0, // API expects one-based page number
      }),
    );
  };

  return (
    <Container
      sx={{
        marginTop: '10px',
        position: 'relative',
        maxWidth: '100% !important',
        padding: '0px  !important',
      }}>
      {statusToggleNo === 1 && (
        <Stack
          marginBottom="12px"
          direction="row"
          justifyContent="flex-end"
          alignItems="center">
          <Box>
            {/* <Button
              data-testid="button-reject"
              variant="outlined"
              size="large"
              className={classes.paperButton}>
              {strings.reject}
            </Button> */}
            <Button
              data-testid="button-approve"
              variant="contained"
              className={classes.applyButton}
              onClick={handleApprovalInvoiceForm}>
              {strings.approve}
            </Button>
          </Box>
        </Stack>
      )}

      {!orderFormDataLoader ? (
        <>
          <TableContainer className={`${classes.noBoxShd}`} component={Paper}>
            <Table sx={{borderCollapse: 'separate', borderSpacing: '0px 1px'}}>
              <TableHead>
                <TableRow className={`${classes.requestformTableHeading}   `}>
                  {statusToggleNo === 1 && tableData?.orderList?.length > 0 && (
                    <TableCell
                      sx={{
                        border: 'none',
                        padding: '0px !important',
                        // position: 'sticky',
                        // left: '0px',
                        // background: '#f4f4f4',
                      }}>
                      <Checkbox
                        sx={{color: '#322b7c !important'}}
                        data-testid="check-all-requestform"
                        checked={
                          checkBox?.length > 0 &&
                          checkBox.every((data: any) => data?.checked === true)
                        }
                        onClick={() => toggleCheckBox('all', 0)}
                      />
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '160px !important',
                      paddingLeft: '44px !important',
                      // position: 'sticky',
                      // left: statusToggleNo === 1 ? '40px' : '0px',
                    }}
                    className={`${classes.requestformTableHeading}  ${classes.cellPadding} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.orderNo}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '160px !important',
                      paddingLeft:
                        statusToggleNo === 1 ? '' : '10px !important',
                    }}
                    className={`${classes.requestformTableHeading}  ${classes.cellPadding}`}>
                    {strings.zho.fcAdmin.dashboard.tableHeading.requestFormNo}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={`${classes.requestformTableHeading} ${classes.requestFormHeadingWidth} `}>
                    {strings.date}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '95px',
                    }}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding}`}>
                    {strings.zho.customer}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingWidth} `}>
                    {strings.hq}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingWidth} `}>
                    {strings.status}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', minWidth: '130px'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} `}>
                    {strings.zho.fcAdmin.dashboard.tableHeading.chemistandDis}
                  </TableCell>

                  <TableCell
                    sx={{border: 'none', minWidth: '130px'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingLgWidth} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.schemeStatus}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={`${classes.requestformTableHeading} ${classes.requestFormHeadingLgWidth} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.SapOrderNo}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px', minWidth: '120px'}}
                    className={`${classes.requestformTableHeading}`}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.SapOrderRemarks}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px', minWidth: '95px'}}
                    className={`${classes.requestformTableHeading}  `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.InvoiceNo}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', minWidth: '110px'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding}`}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.invoiceDate}
                  </TableCell>
                  {/* <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={`${classes.requestformTableHeading} ${classes.requestFormHeadingWidth} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.supply}
                  </TableCell> */}
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} `}>
                    {strings.actions}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.orderList?.length > 0 ? (
                  tableData?.orderList?.map((data: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow className={classes.requestformTbleBoxSdw}>
                          {statusToggleNo === 1 && (
                            <TableCell
                              className={classes.checkBoxShd}
                              sx={{
                                border: 'none !important',
                                padding: '0px !important',
                                // position: 'sticky',
                                // left: '0px',
                                // background: '#f4f4f4',
                              }}>
                              <Checkbox
                                sx={{color: '#322b7c !important'}}
                                data-testid="check-requestform"
                                checked={checkBox[index]?.checked || false}
                                onClick={() =>
                                  toggleCheckBox(index, data?.orderId)
                                }
                              />
                            </TableCell>
                          )}

                          <TableCell
                            sx={{
                              padding: '0px 6px !important',
                              minWidth: '160px',
                              // position: 'sticky',
                              // left: statusToggleNo === 1 ? '40px' : '0px',
                              // background: '#f4f4f4',
                            }}
                            className={` ${classes.checkBoxShd} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }  ${classes.requestFormTableFont} ${
                              classes.productTableLeftBorder
                            } ${classes.borderTopLeft} `}>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center">
                              <Box width="30px" display="inline-block">
                                <IconButton
                                  data-testid="show-comp-prod"
                                  onClick={e => {
                                    e.stopPropagation();
                                  }}>
                                  {/* <ReplayIcon color="primary" /> */}
                                  {openCompetitor.open &&
                                  openCompetitor.id === index ? (
                                    <RemoveCircleOutlineOutlinedIcon
                                      sx={{
                                        height: '18px',
                                        width: '18px',
                                      }}
                                      color="primary"
                                      data-testid="hide-prod-details"
                                      onClick={() =>
                                        setOpenCompetitor({
                                          id: index,
                                          open: false,
                                        })
                                      }
                                    />
                                  ) : (
                                    <AddCircleOutlineOutlinedIcon
                                      sx={{height: '18px', width: '18px'}}
                                      color="primary"
                                      data-testid="show-prod-details"
                                      onClick={() =>
                                        handleInnerClickData(
                                          data?.orderId,
                                          index,
                                        )
                                      }
                                    />
                                  )}
                                </IconButton>
                              </Box>
                              <Box
                                fontSize="11.7px"
                                minWidth="180px"
                                display="inline-block">
                                {data?.orderFormNo}
                              </Box>
                            </Stack>
                          </TableCell>

                          <TableCell
                            sx={{
                              wordBreak: 'break-all !important',
                              minWidth: '180px',
                              paddingLeft:
                                statusToggleNo === 1 ? '' : '10px !important',
                            }}
                            className={`${classes.checkBoxShd}  ${
                              classes.cellPadding
                            } ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }   ${classes.requestFormTableFont}`}>
                            {data?.requestFormNo}
                          </TableCell>
                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont}`}>
                            {DateFormat(data?.createdOn)}
                          </TableCell>

                          <TableCell
                            sx={{
                              minWidth: '90px',
                            }}
                            className={` ${classes.requestFormTableFont} ${classes.cellPadding} `}>
                            {data?.customer}
                          </TableCell>
                          <TableCell
                            className={` ${classes.requestFormTableFont} ${classes.cellPadding}`}>
                            {data?.hq}
                          </TableCell>
                          <TableCell
                            style={{
                              color: getStatusColor(data?.statusCode),
                              fontWeight: '600',
                              minWidth: '90px',
                            }}
                            className={` ${classes.cellPadding}  ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            <Stack direction="row" alignItems="center" gap={1}>
                              <Typography
                                className={classes.requestFormTableFont}
                                style={{
                                  color: getStatusColor(data?.statusCode),
                                  padding: '10px !important',
                                }}>
                                {data?.status}
                              </Typography>

                              {/* {data?.statusCode === 3 && (
                                <Stack
                                  alignItems="center"
                                  justifyContent="center">
                                  <LightTooltip
                                    title={
                                      <>
                                        <Typography
                                          fontSize="11.7px"
                                          fontWeight="400"
                                          variant="caption"
                                          key={index}>
                                          {data?.pendingAt}
                                        </Typography>
                                      </>
                                    }>
                                    <InfoOutlinedIcon
                                      data-testid="show-customers"
                                      sx={{height: '15px', width: '15px'}}
                                      color="primary"
                                    />
                                  </LightTooltip>
                                </Stack>
                              )} */}
                            </Stack>
                          </TableCell>
                          <TableCell
                            className={`  ${classes.requestFormTableFont} ${classes.cellPadding}`}>
                            {data?.chemist}
                          </TableCell>

                          <TableCell
                            sx={{
                              minWidth: '130px',
                              color:
                                data?.schemeStatus === 'Active'
                                  ? '#01a685'
                                  : '#f45b60',
                              fontWeight: '600',
                            }}
                            className={`  ${classes.requestFormTableFont} ${classes.cellPadding}`}>
                            {data?.schemeStatus}
                          </TableCell>

                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont}`}>
                            {data?.sapOrderNo}
                          </TableCell>

                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont}`}>
                            {data?.sapOrderRemarks}
                          </TableCell>

                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont}`}>
                            {data?.invoiceNo}
                          </TableCell>

                          <TableCell
                            className={`${classes.requestFormTableFont} ${classes.cellPadding}`}>
                            {data.invoiceDate !== null
                              ? DateFormat(data?.invoiceDate)
                              : '---'}
                          </TableCell>
                          {/* 
                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont}`}>
                            {data?.supply}
                          </TableCell> */}

                          <TableCell
                            className={`  ${classes.requestFormTableFont} ${classes.cellPadding}   ${classes.borderTopRight} ${classes.productTableRightBorder}`}>
                            <Typography
                              onClick={() => {
                                handleOpenReviewPopup(data?.orderId);
                                setOrderFormId(data?.orderId);
                                orderId.current = data?.orderId;
                                dispatch(setCurrentOrderId(data?.orderId));
                              }}
                              data-testid="review-request"
                              sx={{
                                fontSize: '11.7px',
                                cursor: 'pointer',
                              }}
                              variant="subtitle2"
                              color="primary">
                              {strings.zho.review}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {openCompetitor.open && openCompetitor.id === index && (
                          <TableRow>
                            {!orderInnerFromLoading ? (
                              <TableCell
                                sx={{
                                  padding:
                                    statusToggleNo === 1
                                      ? '0px 0px 8px 40px'
                                      : '0px 0px 8px 0px',
                                  border: 'none !important',
                                }}
                                colSpan={7}>
                                <OrderProductDetails
                                  orderInnerFormData={orderInnerFormData}
                                  hide={true}
                                />
                              </TableCell>
                            ) : (
                              <Box
                                className={classes.loader}
                                sx={[
                                  {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 3,
                                  },
                                ]}>
                                <CircularProgress />
                              </Box>
                            )}
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell sx={{textAlign: 'center'}} colSpan={9}>
                      Currently there are no Invoice!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={+tableData?.totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <TableContainer sx={{height: '90px'}}>
          <Table>
            <TableBody>
              <TableRow>
                <Box
                  // className={classes.loader}
                  sx={[
                    {
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 3,
                    },
                  ]}>
                  <CircularProgress />
                </Box>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default InvoiceFormTable;
