import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  scrollBarStyle: {
    width: '100% !important',
    height: '55px !important',
    marginRight: '10px',
  },
  dropdownBgWhite: {
    backgroundColor: '#fff',
  },
  filterGrid: {
    width: 'fit-content',
    overflowX: 'auto',
    overflowY: 'hidden',
    flexWrap: 'nowrap',
    backgroundColor: '#F4F4F4',
  },
  dropdown: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 150,
    maxWidth: 150,
    fontSize: '12px',
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white',
    },
    icon: {
      fill: 'white',
    },
    root: {
      color: 'white',
    },
  },
  remarksDropdown: {
    overflow: 'hidden',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    minWidth: '280px',
    maxWidth: '280px',
    fontSize: '12px',
  },
  filter: {
    backgroundColor: '#fff',
    minWidth: 160,
    maxWidth: 160,
    '& .MuiInputBase-input': {
      fontSize: 12.7,
    },
    '& .MuiInputLabel-root': {
      fontSize: 12.7,
    },
    '& .MuiSvgIcon-root': {
      fill: '#322b7c',
    },
  },

  filterFromDate: {
    backgroundColor: '#fff',
    minWidth: 156,
    maxWidth: 156,
    '& .MuiInputBase-input': {
      fontSize: 12.7,
      height: '17px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: 12.7,
    },
    '& .MuiSvgIcon-root': {
      fill: '#322b7c',
    },
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
  },

  filterToDate: {
    backgroundColor: '#fff',
    minWidth: 140,
    maxWidth: 140,
    '& .MuiInputBase-input': {
      fontSize: 12.7,
      height: '17px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: 12.7,
    },
    '& .MuiSvgIcon-root': {
      fill: '#322b7c',
    },
  },
  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '160px',
    height: '35px',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.white,
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '160px',
    height: '35px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    textTransform: 'capitalize',
    textAlign: 'center',
    marginLeft: 10,
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
    '&:disabled': {
      backgroundColor: Colors.grey[100],
      border: `4px solid ${Colors.grey[100]}`,
    },
  },

  rejectionButtonCancel: {
    border: `1.5px solid #322b7c`,
  },

  rejectionButtonRej: {
    backgroundColor: '#da3131',
    border: 'none !important',
    '&:hover': {
      background: '#E17F7F',
    },
  },
  errorBorder: {
    border: '1px solid red !important',
  },

  rejectedTextArea: {
    width: '100%',
    padding: '2px 5px',
    border: '1px solid lightgrey',
    borderRadius: '3px',
    '&:focus': {
      outline: 'none !important',
      border: '2px solid #322b7c',
    },
  },

  button: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: 'auto',
    fontFamily: 'Poppins',
    height: '51.68px',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
    '&:disabled': {
      backgroundColor: Colors.grey[100],
      border: `3px solid ${Colors.grey[100]}`,
    },
  },
  productListingDropdown: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 280,
    fontSize: '13px',
  },
  productListFormControl: {
    minWidth: '280px !important',
  },
  font11: {
    fontSize: '11px',
  },
  font12: {
    fontSize: '12px',
  },
  font13: {
    fontSize: '13px',
  },

  toolbarRequestForm: {
    backgroundColor: '#f4f4f4',
    color: '#1e1c3b',
    padding: '10px',
    // paddingTop: '17px',
    borderRadius: '5px',
    // margin: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    alignItems: 'center',
    '& .ScrollbarsCustom .ScrollbarsCustom-Track': {
      height: '5px !important',
    },
    '& .ScrollbarsCustom-Content': {
      paddingTop: '6px !important',
    },
  },
  requestFormgo: {
    backgroundColor: '#322b7c',
    borderRadius: '5px',
    width: 'auto',
    color: '#fff',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12.7,
    marginTop: '0px',
    minWidth: '40px',
    height: '40px',
    marginLeft: '2px',
    '&:hover': {
      background: '#322b7c',
    },
  },

  orderInfoButton: {
    position: 'absolute',
    top: '8px',
    right: '10px',
    height: '15px',
    width: '15px',
    color: '#da3131',
  },
  textField: {
    border: 'transparent',
    color: '#322B7C',
    borderRadius: '5px',
    width: '100%',
    padding: '0px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '11.7px !important',
    '& input::placeholder': {
      fontSize: '11.7px !important',
    },
    '& input': {
      fontSize: '11.7px',
    },
    '& .MuiAutocomplete-input': {
      width: '.5px !important',
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: '11.7px !important', // Font size of the placeholder
    },
  },


  filterLoader: {
    position: 'absolute',
    left: '130px',
  },


}));
