import {API_PATH, HTTP_OK} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {takeLatest, call, put, takeEvery} from 'redux-saga/effects';
import {
  fcDashboardActions,
  fetchStateDataTypeName,
  fetchHqDataTypeName,
  fetchMrDataTypeName,
  fetchRequestFormDataTypeName,
  fetchRequestFormInnerDataTypeName,
  fetchDivisionDataTypeName,
  fetchUserApprovalDataTypeName,
  fetchUpdateUserApprovalDataTypeName,
  fetchAddProductTotalDiscountDataTypeName,
  fetchProductForDiscountDataTypeName,
  fetchProductListFromTotalDiscountFilterDataTypeName,
  fetchProductListFromTotalDiscountDataTypeName,
  fetchGenerateProductExcelFileDataTypeName,
  fetchUploadProductExcelFileDataTypeName,
  fetchUpdateProductTotalDiscountDataTypeName,
  fetchDeleteProductTotalDiscountDataTypeName,
  fetchReviewFormApprovalDataTypeName,
  fetchReviewFormProductDataTypeName,
  fetchReviewUpdateApprovalPeriodTypeName,
  fetchReviewFormUpdateAdditonalQtyTypeName,
  fetchReviewFormDeleteProductTypeName,
  fetchReviewFormRevertProductTypeName,
  fetchFormApprovalReviewTypeName,
  fetchReviewFormRejectProductTypeName,
  fetchFormRejectReviewDropDownTypeName,
  fetchProductListFromTotalDiscountDataCreator,
  fetchManageFilterProductTypeName,
  fetchManageFilterCandFTypeName,
  fetchManageFilterHqTypeName,
  fetchManageFilterCustomerTypeName,
  fetchManageFilterChemistTypeName,
  fetchManageProductAddTypeName,
  fetchManageProductTableTypeName,
  fetchManageProductPopupDetailsTypeName,
  fetchManageProductDeleteTypeName,
  fetchManageProductTableCreator,
  fetchManageProductExcelDownloadTypeName,
  fetchFcsHeaderCountTypeName,
} from './slice';
import {redirectToLogin} from '@app/screens/zho/utils/handleAuth';
import {TabNames} from '../redux/tabName';

// State Dropdown watcher
export function* fetchFcStateDataWatcher() {
  yield takeEvery(fetchStateDataTypeName, fetchFcStateDataWorker);
}

// State Dropdown worker
export function* fetchFcStateDataWorker(actions: any): any {
  const currentStaffPositionId = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.dropdowns.state}${currentStaffPositionId}`;
  try {
    yield put(fcDashboardActions.setStateLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setStateData(response?.data?.response));
    } else {
      yield put(fcDashboardActions.setStateError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setStateError());
  }
}

// Hq Dropdown watcher
export function* fetchFcHqDataWatcher() {
  yield takeEvery(fetchHqDataTypeName, fetchFcHqDataWorker);
}

// Hq Dropdown worker
export function* fetchFcHqDataWorker(actions: any): any {
  const currentstateID = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.dropdowns.hq}${currentstateID}`;
  try {
    yield put(fcDashboardActions.setHqLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setHqData(response?.data?.response));
    } else {
      yield put(fcDashboardActions.setHqError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setHqError());
  }
}

// Mr Dropdown watcher
export function* fetchFcMrDataWatcher() {
  yield takeLatest(fetchMrDataTypeName, fetchFcMrDataWorker);
}

// Mr Dropdown worker
export function* fetchFcMrDataWorker(actions: any): any {
  const hqids = actions.payload;

  const url = `${API_PATH.zho.fcDashboard.dropdowns.mr}`;
  try {
    yield put(fcDashboardActions.setMrLoading());
    const response = yield call(NetworkService.post, url, hqids, {});
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setMrData(response?.data?.response));
    } else {
      yield put(fcDashboardActions.setMrError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setMrError());
  }
}

// requestform table data
export function* fetchFcRequestFormDataWatcher() {
  yield takeLatest(fetchRequestFormDataTypeName, fetchFcRequestFormDataWorker);
}

// requestform table data
export function* fetchFcRequestFormDataWorker(actions: any): any {
  const requestformTableObj = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.requestForm.tableData}${requestformTableObj.statusToggleNo}`;
  try {
    yield put(fcDashboardActions.setRequestFormLoading());
    const response = yield call(
      NetworkService.post,
      url,
      requestformTableObj.currentRequestStatus,
      {},
    );
    if (response.status === HTTP_OK) {
      yield put(
        TabNames.actions.updateAdminTabs({
          index: 0,
          value: `Request-From(${response?.data?.response?.totalCount || 0})`,
        }),
      );
      yield put(
        fcDashboardActions.setRequestFormData(response?.data?.response),
      );
    } else {
      yield put(fcDashboardActions.setRequestFormError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setRequestFormError());
  }
}

// requestInnerform table data
export function* fetchFcRequestInnerFormDataWatcher() {
  yield takeLatest(
    fetchRequestFormInnerDataTypeName,
    fetchFcRequestFormInnerDataWorker,
  );
}

// requestInnerform table data
export function* fetchFcRequestFormInnerDataWorker(actions: any): any {
  const requestInnerData = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.requestForm.innerTableData}${
    requestInnerData.currentRequestID + '&IsDraft='
  }${requestInnerData.isDraftValue}`;
  try {
    yield put(fcDashboardActions.setRequestInnerFormLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setRequestInnerFormData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setRequestInnerFormError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setRequestInnerFormError());
  }
}

// division dropdown data
export function* fetchZhoDivisionDataWatcher() {
  yield takeLatest(fetchDivisionDataTypeName, fetchDivisionDataWorker);
}

// division dropdown data
export function* fetchDivisionDataWorker(actions: any): any {
  const {adminId} = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.dropdowns.division}${adminId}`;
  try {
    yield put(fcDashboardActions.setDivisionLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setDivisionData(response?.data?.response));
    } else {
      yield put(fcDashboardActions.setDivisionError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setDivisionError());
  }
}

// User approval table data
export function* fetchZhoUserApprovalDataWatcher() {
  yield takeLatest(fetchUserApprovalDataTypeName, fetchUserApprovalDataWorker);
}

// User Approval table data
export function* fetchUserApprovalDataWorker(actions: any): any {
  const {divisionId} = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.manageUserRole.userApproval}${divisionId}`;
  try {
    // yield put(fcDashboardActions.setUserApprovalLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setUserApprovalData(response?.data?.response),
      );
    } else {
      yield put(fcDashboardActions.setUserApprovalError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setUserApprovalError());
  }
}

// Update User Approval
export function* fetchZhoUpdateUserApprovalDataWatcher() {
  yield takeLatest(
    fetchUpdateUserApprovalDataTypeName,
    fetchUpdateUserApprovalDataWorker,
  );
}

// Update User Approval
export function* fetchUpdateUserApprovalDataWorker(actions: any): any {
  const {tableType, tableData} = actions.payload;
  let url = '';
  if (tableType === 'order') {
    url = API_PATH.zho.fcDashboard.manageUserRole.addorderapprovaluserroles;
  } else {
    url = API_PATH.zho.fcDashboard.manageUserRole.addrequestapprovaluserroles;
  }
  try {
    yield put(fcDashboardActions.setUpdateUserApprovalLoading());
    const response = yield call(NetworkService.post, url, tableData);
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setUpdateUserApprovalCompleted());
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'User Roles updated successfully!',
        ToastType.SUCCESS,
      );
    } else {
      yield put(fcDashboardActions.setUpdateUserApprovalError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setUpdateUserApprovalError());
  }
}

// Add Product Total Discount
export function* fetchZhoAddProductTotalDiscountDataWatcher() {
  yield takeLatest(
    fetchAddProductTotalDiscountDataTypeName,
    fetchAddProductTotalDiscountDataWorker,
  );
}

// Add product Total Discount
export function* fetchAddProductTotalDiscountDataWorker(actions: any): any {
  const {payload} = actions.payload;
  const url =
    API_PATH.zho.fcDashboard.updataeTotalTradeDiscount.addProductTotalDiscount;
  try {
    yield put(fcDashboardActions.setAddProductTotalDiscountLoading());
    const response = yield call(NetworkService.post, url, payload);
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setAddProductTotalDiscountCompleted());
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Product Discount added successfully!',
        ToastType.SUCCESS,
      );
    } else {
      yield put(fcDashboardActions.setAddProductTotalDiscountError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setAddProductTotalDiscountError());
  }
}

// Product for discount data
export function* fetchZhoProductForDiscountDataWatcher() {
  yield takeLatest(
    fetchProductForDiscountDataTypeName,
    fetchProductForDiscountDataWorker,
  );
}

// Product for discount data
export function* fetchProductForDiscountDataWorker(actions: any): any {
  const {divisionId} = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.updataeTotalTradeDiscount.getProductForDiscount}${divisionId}`;
  try {
    yield put(fcDashboardActions.setProductForDiscountLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setProductForDiscountData(response?.data?.response),
      );
    } else {
      yield put(fcDashboardActions.setProductForDiscountError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setProductForDiscountError());
  }
}

// Product list from total discount filter
export function* fetchZhoProductListFromTotalDiscountFilterDataWatcher() {
  yield takeLatest(
    fetchProductListFromTotalDiscountFilterDataTypeName,
    fetchProductListFromTotalDiscountFilterDataWorker,
  );
}

// Product list from total discount filter
export function* fetchProductListFromTotalDiscountFilterDataWorker(
  actions: any,
): any {
  const {divisionId} = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.updataeTotalTradeDiscount.getproductListFromTotalDiscountFilter}${divisionId}`;
  try {
    yield put(
      fcDashboardActions.setProductListFromTotalDiscountFilterLoading(),
    );
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setProductListFromTotalDiscountFilterData(
          response?.data?.response,
        ),
      );
    } else {
      yield put(
        fcDashboardActions.setProductListFromTotalDiscountFilterError(),
      );
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setProductListFromTotalDiscountFilterError());
  }
}

// Product list from total discount data
export function* fetchZhoProductListFromTotalDiscountDataWatcher() {
  yield takeLatest(
    fetchProductListFromTotalDiscountDataTypeName,
    fetchProductListFromTotalDiscountDataWorker,
  );
}

// Product list from total discount data
export function* fetchProductListFromTotalDiscountDataWorker(
  actions: any,
): any {
  const {payload} = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.updataeTotalTradeDiscount.getproductListFromTotalDiscount}`;
  try {
    yield put(fcDashboardActions.setProductListFromTotalDiscountLoading());
    const response = yield call(NetworkService.post, url, payload);
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setProductListFromTotalDiscountData(
          response?.data?.response,
        ),
      );
    } else {
      yield put(fcDashboardActions.setProductListFromTotalDiscountError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setProductListFromTotalDiscountError());
  }
}

// Generate download product list template
export function* fetchZhoGenerateProductExcelFileDataWatcher() {
  yield takeLatest(
    fetchGenerateProductExcelFileDataTypeName,
    fetchGenerateProductExcelFileDataWorker,
  );
}

// Generate download product list template
export function* fetchGenerateProductExcelFileDataWorker(actions: any): any {
  const {divisionId} = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.updataeTotalTradeDiscount.generateProductExcelFile}${divisionId}`;
  try {
    yield put(fcDashboardActions.setGenerateProductExcelFileLoading());
    const response = yield call(NetworkService.post, url, {});
    if (response.status === HTTP_OK) {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response?.data?.response?.base64}`;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = linkSource;
      downloadLink.target = '_self';
      downloadLink.download = response?.data?.response?.fileName;
      downloadLink.click();
    } else {
      yield put(fcDashboardActions.setGenerateProductExcelFileError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setGenerateProductExcelFileError());
  }
}

// Upload product excel file
export function* fetchZhoUploadProductExcelFileDataWatcher() {
  yield takeLatest(
    fetchUploadProductExcelFileDataTypeName,
    fetchUploadProductExcelFileDataWorker,
  );
}

// Upload product excel file
export function* fetchUploadProductExcelFileDataWorker(actions: any): any {
  const {payload} = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.updataeTotalTradeDiscount.uploadProductExcel}${payload?.id}`;
  const formdata = new FormData();
  formdata.append('file', payload?.file);
  try {
    yield put(fcDashboardActions.setUploadProductExcelFileLoading());
    const response = yield call(NetworkService.post, url, formdata);
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setUploadProductExcelFileCompleted());
      yield put(
        fcDashboardActions.setUploadProductExcelFileData(
          response.data.response,
        ),
      );
    } else {
      yield put(fcDashboardActions.setUploadProductExcelFileError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setUploadProductExcelFileError());
  }
}

// Update product total discount
export function* fetchZhoUpdateProductTotalDiscountDataWatcher() {
  yield takeLatest(
    fetchUpdateProductTotalDiscountDataTypeName,
    fetchUpdateProductTotalDiscountDataWorker,
  );
}

// Update product total discount
export function* fetchUpdateProductTotalDiscountDataWorker(actions: any): any {
  const {payload} = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.updataeTotalTradeDiscount.editTotalDiscount}`;
  try {
    yield put(fcDashboardActions.setUpdateProductTotalDiscountLoading());
    const response = yield call(NetworkService.put, url, payload);
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setUpdateProductTotalDiscountCompleted());
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Discount Updated Successfully',
        ToastType.SUCCESS,
      );
    } else {
      yield put(fcDashboardActions.setUpdateProductTotalDiscountError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setUpdateProductTotalDiscountError());
  }
}

// Update product total discount
export function* fetchZhoDeleteProductTotalDiscountDataWatcher() {
  yield takeLatest(
    fetchDeleteProductTotalDiscountDataTypeName,
    fetchDeleteProductTotalDiscountDataWorker,
  );
}

// Update product total discount
export function* fetchDeleteProductTotalDiscountDataWorker(actions: any): any {
  const {payload} = actions.payload;

  const url = `${API_PATH.zho.fcDashboard.updataeTotalTradeDiscount.deleteProductFromProductListing}${payload.id}`;
  try {
    yield put(fcDashboardActions.setDeleteProductTotalDiscountLoading());
    const response = yield call(NetworkService.Delete, url, {});
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setDeleteProductTotalDiscountCompleted());
      yield put(
        fetchProductListFromTotalDiscountDataCreator({
          divisionId: payload.divisionId,
          productId: payload.productId,
        }),
      );
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Product Deleted Successfully',
        ToastType.SUCCESS,
      );
    } else {
      yield put(fcDashboardActions.setDeleteProductTotalDiscountError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setDeleteProductTotalDiscountError());
  }
}

// approvalStatus data
export function* fetchFcRequestReviewFormDataWatcher() {
  yield takeLatest(
    fetchReviewFormApprovalDataTypeName,
    fetchFcRequestReviewFormDataWorker,
  );
}

export function* fetchFcRequestReviewFormDataWorker(actions: any): any {
  const requestId = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.requestForm.reviewForm.approvalStatus}${requestId.currentRequestID}`;

  try {
    yield put(fcDashboardActions.setReviewFormApprovalLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setReviewFormApprovalData(response?.data?.response),
      );
    } else {
      yield put(fcDashboardActions.setReviewFormApprovalError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setReviewFormApprovalError());
  }
}

// product details data

export function* fetchFcRequestReviewFormProductDataWatcher() {
  yield takeLatest(
    fetchReviewFormProductDataTypeName,
    fetchFcRequestReviewFormProductDataWorker,
  );
}

export function* fetchFcRequestReviewFormProductDataWorker(actions: any): any {
  const requestId = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.requestForm.reviewForm.productDetails}${requestId.currentRequestID}`;

  try {
    yield put(fcDashboardActions.setReviewFormProductLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setReviewFormProductData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setReviewFormProductError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setReviewFormProductError());
  }
}

// update Approval Period

export function* fetchFcRequestReviewUpdateApprovalPeriodWatcher() {
  yield takeLatest(
    fetchReviewUpdateApprovalPeriodTypeName,
    fetchFcRequestReviewUpdateApprovalPeriodWorker,
  );
}

export function* fetchFcRequestReviewUpdateApprovalPeriodWorker(
  actions: any,
): any {
  const requestId = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.requestForm.reviewForm.approvalPeriodUpdate
  }${requestId.currentRequestID.requestId}${
    '&Days=' + requestId.currentRequestID.days
  }${'&currentUserId=' + requestId.currentRequestID.currentUserId}`;

  try {
    yield put(fcDashboardActions.setReviewFormProductLoading());
    const response = yield call(NetworkService.put, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setReviewFormApprovalPeriodReCall());
      ToasterService.hideToaster();
      ToasterService.showToaster(response.data.response, ToastType.SUCCESS);
    } else {
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
      yield put(fcDashboardActions.setReviewFormApprovalPeriodError());
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setReviewFormApprovalPeriodError());
  }
}

// update additional discount data
export function* fetchFcRequestReviewFormAdditonalQtyWatcher() {
  yield takeLatest(
    fetchReviewFormUpdateAdditonalQtyTypeName,
    fetchFcRequestreviewFormAdditonalQtyWorker,
  );
}

export function* fetchFcRequestreviewFormAdditonalQtyWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.requestForm.reviewForm.additionalDiscountUpdate}${payloadObj.currentUserId}`;
  try {
    yield put(fcDashboardActions.setReviewFormProductLoading());
    const response = yield call(
      NetworkService.put,
      url,
      payloadObj?.reqPayload,
      {},
    );
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setReviewFormAdditonalQtyReCall());
      ToasterService.hideToaster();
      ToasterService.showToaster(response.data.response, ToastType.SUCCESS);
    } else {
      yield put(fcDashboardActions.setReviewFormAdditonalQtyError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setReviewFormAdditonalQtyError());
  }
}

// Deleting the product details
export function* fetchFcRequestReviewFormDeletingProductWatcher() {
  yield takeLatest(
    fetchReviewFormDeleteProductTypeName,
    fetchFcRequestReviewFormDeletingProductWorker,
  );
}

export function* fetchFcRequestReviewFormDeletingProductWorker(
  actions: any,
): any {
  const payloadObj = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.requestForm.reviewForm.deleteProducListing
  }${payloadObj.currentId}${'&currentUserId=' + payloadObj.userId}`;
  try {
    yield put(fcDashboardActions.setReviewFormProductLoading());
    const response = yield call(NetworkService.put, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setReviewFormApprovalPeriodReCall());
    } else {
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
      yield put(fcDashboardActions.setReviewFormDeleteProductError());
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setReviewFormDeleteProductError());
  }
}

// REverting the product details
export function* fetchFcRequestReviewFormRevertingProductWatcher() {
  yield takeLatest(
    fetchReviewFormRevertProductTypeName,
    fetchFcRequestReviewFormRevertingProductWorker,
  );
}

export function* fetchFcRequestReviewFormRevertingProductWorker(
  actions: any,
): any {
  const payloadObj = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.requestForm.reviewForm.reactivateProductRequest
  }${payloadObj.currentId.id}${
    '&currentUserId=' + payloadObj.currentId.userId
  }`;
  try {
    yield put(fcDashboardActions.setReviewFormRevertProductLoading());
    const response = yield call(NetworkService.put, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(fcDashboardActions.setReviewFormApprovalPeriodReCall());
    } else {
      yield put(fcDashboardActions.setReviewFormRevertProductError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setReviewFormRevertProductError());
  }
}

// approve request
export function* fetchFcRequestReviewApprovalProductWatcher() {
  yield takeLatest(
    fetchFormApprovalReviewTypeName,
    fetchFcRequestReviewApprovalProductWorker,
  );
}

export function* fetchFcRequestReviewApprovalProductWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.requestForm.reviewForm.approveRequestProduct}${payloadObj.currentStaffId}&currentUserId=${payloadObj.currentUserId}`;

  try {
    yield put(fcDashboardActions.setFormApprovalReviewLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payloadObj.requestIds,
      {},
    );
    if (response.status === HTTP_OK) {
      if (payloadObj.typeApproval === 'pApproval') {
        ToasterService.hideToaster();
        ToasterService.showToaster(
          'Request Partially Approved Successfully!',
          ToastType.SUCCESS,
        );
      } else {
        ToasterService.hideToaster();
        ToasterService.showToaster(
          'Request Successfully Approved!',
          ToastType.SUCCESS,
        );
      }
      yield put(fcDashboardActions.setFormApprovalReviewReCall());
    } else {
      yield put(fcDashboardActions.setFormApprovalReviewError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setFormApprovalReviewError());
  }
}

// Rejecting the product details
export function* fetchFcRequestReviewRejectProductWatcher() {
  yield takeLatest(
    fetchReviewFormRejectProductTypeName,
    fetchFcRequestReviewRejectProductWorker,
  );
}

export function* fetchFcRequestReviewRejectProductWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.requestForm.reviewForm.rejectRequestProduct}${payloadObj.requestId}&RejectionRemarks=${payloadObj.rejectionRemarks}&staffPosId=${payloadObj.currentStaffId}&currentUser=${payloadObj.currentUserId}`;
  try {
    yield put(fcDashboardActions.setFormRejectProductLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payloadObj.requestIds,
      {},
    );
    if (response.status === HTTP_OK) {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Request Rejected Approved!',
        ToastType.SUCCESS,
      );
      yield put(fcDashboardActions.setFormRejectReviewReCall());
    } else {
      yield put(fcDashboardActions.setFormRejectProductError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setFormRejectProductError());
  }
}

// remarks rejeceted dropdown

// Rejecting the product details
export function* fetchFcRejectedRemarksDropDownWatcher() {
  yield takeLatest(
    fetchFormRejectReviewDropDownTypeName,
    fetchFcRejectedRemarksDropDownWorker,
  );
}

export function* fetchFcRejectedRemarksDropDownWorker(actions: any): any {
  const url = `${API_PATH.zho.fcDashboard.requestForm.reviewForm.rejectedRemarks}`;
  try {
    yield put(fcDashboardActions.setReviewRejectRemarksLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setReviewRejectRemarksData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setReviewRejectRemarksError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setReviewRejectRemarksError());
  }
}

// to get product by Division Id

export function* fetchManageFilterProductWatcher() {
  yield takeLatest(
    fetchManageFilterProductTypeName,
    fetchManageFilterProductWorker,
  );
}

export function* fetchManageFilterProductWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.manageProductStatus.getProductsByDivisionId +
    payloadObj.divisionId
  }`;
  try {
    yield put(fcDashboardActions.setManageFilterProductLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setManageFilterProductData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setManageFilterProductError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setManageFilterProductError());
  }
}

// to get candf by Division Id

export function* fetchManageFilterCandFWatcher() {
  yield takeLatest(
    fetchManageFilterCandFTypeName,
    fetchManageFilterCandFWorker,
  );
}

export function* fetchManageFilterCandFWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.manageProductStatus.getCandFByDivisionId +
    payloadObj.candFId
  }`;
  try {
    yield put(fcDashboardActions.setManageFilterCandFLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setManageFilterCandFData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setManageFilterCandFError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setManageFilterCandFError());
  }
}

// to get hq by candf id

export function* fetchManageFilterHqWatcher() {
  yield takeLatest(fetchManageFilterHqTypeName, fetchManageFilterHqWorker);
}

export function* fetchManageFilterHqWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.manageProductStatus.getHqByCandFId}`;
  try {
    yield put(fcDashboardActions.setManageFilterHqLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payloadObj.candFId,
      {},
    );
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setManageFilterHqData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setManageFilterHqError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setManageFilterHqError());
  }
}

// to get customer by candf and division id

export function* fetchManageFilterCustomerWatcher() {
  yield takeLatest(
    fetchManageFilterCustomerTypeName,
    fetchManageFilterCustomerWorker,
  );
}

export function* fetchManageFilterCustomerWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.manageProductStatus.getStockistByCandFId +
    payloadObj?.divisionId
  }`;
  try {
    yield put(fcDashboardActions.setManageFilterCustomerLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payloadObj.candFIds,
      {},
    );
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setManageFilterCustomerData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setManageFilterCustomerError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setManageFilterCustomerError());
  }
}

// to get chemist based on hq and division id

export function* fetchManageFilterChemistWatcher() {
  yield takeLatest(
    fetchManageFilterChemistTypeName,
    fetchManageFilterChemistWorker,
  );
}

export function* fetchManageFilterChemistWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.manageProductStatus.getChemistByHqIds +
    payloadObj?.divisionId
  }`;
  try {
    yield put(fcDashboardActions.setManageFilterChemistLoading());
    const response = yield call(NetworkService.post, url, payloadObj.hqIds, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setManageFilterChemistData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setManageFilterChemistError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setManageFilterChemistError());
  }
}

// to add product in mangestatus

export function* fetchManageProductAddWatcher() {
  yield takeLatest(fetchManageProductAddTypeName, fetchManageProductAddWorker);
}

export function* fetchManageProductAddWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.manageProductStatus.addProductManageStatus}`;
  try {
    yield put(fcDashboardActions.setManageProductAddMsgLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payloadObj.addPayload,
      {},
    );
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setManageProductAddReponse(response?.data.response),
      );

      yield put(fetchManageProductTableCreator(payloadObj.payObj));
    } else {
      yield put(fcDashboardActions.setManageProductAddMsgError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setManageProductAddMsgError());
  }
}

// to get manage product table data

export function* fetchManageProductTableWatcher() {
  yield takeLatest(
    fetchManageProductTableTypeName,
    fetchManageProductTableWorker,
  );
}

export function* fetchManageProductTableWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.manageProductStatus.getProductManageStatus}`;
  try {
    yield put(fcDashboardActions.setManageProductTableLoading());
    const response = yield call(
      NetworkService.post,
      url,
      payloadObj.payObj,
      {},
    );
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setManageProductTableData(response?.data.response),
      );
      yield put(
        fcDashboardActions.setMangeProductTableCount(
          Math.ceil(response?.data.response.totalCount / 10),
        ),
      );
    } else {
      yield put(fcDashboardActions.setManageProductTableError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setManageProductTableError());
  }
}

// to get manage product popup details

export function* fetchManageProductPopupWatcher() {
  yield takeLatest(
    fetchManageProductPopupDetailsTypeName,
    fetchManageProductPopupWorker,
  );
}

export function* fetchManageProductPopupWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.manageProductStatus.getAddDetailsForProductStatus +
    payloadObj.userId
  }`;
  try {
    yield put(fcDashboardActions.setManageProductPopupLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setManageProductPopupData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setManageProductPopupError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setManageProductPopupError());
  }
}

/// to delete the product from manage product status

export function* fetchManageProductDeleteWatcher() {
  yield takeLatest(
    fetchManageProductDeleteTypeName,
    fetchManageProductDeleteWorker,
  );
}

export function* fetchManageProductDeleteWorker(actions: any): any {
  const payloadObj = actions.payload;
  const url = `${
    API_PATH.zho.fcDashboard.manageProductStatus.deleteProductStatusEntry +
    payloadObj.pId +
    '&currentuser=' +
    payloadObj.userId
  }`;
  try {
    yield put(fcDashboardActions.setManageProductDeleteLoading());
    const response = yield call(NetworkService.Delete, url, {}, {});
    if (response.status === HTTP_OK) {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Transaction Unblocked Successfully!',
        ToastType.SUCCESS,
      );
      yield put(fetchManageProductTableCreator(payloadObj.payObj));
    } else {
      yield put(fcDashboardActions.setmanageProductDeleteError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    yield put(fcDashboardActions.setmanageProductDeleteError());
  }
}

export function* fetchManageProductExcelDownloadWatcher() {
  yield takeEvery(
    fetchManageProductExcelDownloadTypeName,
    fetchManageProductExcelDownloadWorker,
  );
}

// State Dropdown worker
export function* fetchManageProductExcelDownloadWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.fcDashboard.manageProductStatus.generateDeleteProductExcel}${payObj.currentStaffId}`;
  try {
    yield put(fcDashboardActions.setManageProductExcelDownloadLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response?.data?.response?.base64}`;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = linkSource;
      downloadLink.target = '_self';
      downloadLink.download = response?.data?.response?.fileName;
      downloadLink.click();
    } else {
      yield put(fcDashboardActions.setManageProductExcelDownloadError());
      ToasterService.hideToaster();
      ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setManageProductExcelDownloadError());
  }
}

// header count  watcher
export function* fetchFcadminHeaderCountWatcher() {
  yield takeEvery(fetchFcsHeaderCountTypeName, fetchFcadminHeaderCountWorker);
}

// header count  worker
export function* fetchFcadminHeaderCountWorker(actions: any): any {
  const payObj = actions.payload;
  const url = `${API_PATH.zho.headerCount.fcs}${payObj.currentStaffId}`;
  try {
    yield put(fcDashboardActions.setFcadminHeaderCountLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(
        fcDashboardActions.setfcadminHeaderCountData(response?.data.response),
      );
    } else {
      yield put(fcDashboardActions.setfcadminHeaderCountError());
    }
  } catch (error) {
    redirectToLogin();
    yield put(fcDashboardActions.setfcadminHeaderCountError());
  }
}
