import {Button, CircularProgress, Typography} from '@mui/material';
import {Box, Stack} from '@mui/system';
import {ErrorTableStyles as styles} from '@app/screens/zho/pages/fc-admin/manage-product-status/components/errorTableStyles';
import {useStyles as tableStyle} from '@app/screens/zho/components/grid-table/styles';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {strings} from '@app/common/strings';
import {RootStateOrAny, useSelector} from 'react-redux';

const OrderApproveTable = ({setApproveModalOpen, approveModalOpen}: any) => {
  const tableStyles = tableStyle();

  const handleToggleModal = () => {
    setApproveModalOpen(!approveModalOpen);
  };

  const approvedTableList = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderProductApprovalMsg,
  );

  const approvedTableLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderProductApprovalLoading,
  );

  const columns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: strings.zho.hoAdmin.dashboard.tableHeading.orderNo,
      headerClassName: 'boldHeader',
      width: 150,
      sortable: false,
      valueGetter: params => params.row.orderNumber || '---',
    },
    {
      field: 'message',
      headerName: strings.zho.hoAdmin.dashboard.modalPopup.sapRemarks,
      headerClassName: 'boldHeader',
      width: 200,
      sortable: false,
      valueGetter: params => params.row.message || '---',
    },
  ];

  return (
    <>
      <Box
        sx={[
          styles.modalSection,
          styles.modalHeader,
          {display: 'flex', gap: 2, flexWrap: 'wrap'},
        ]}>
        <Stack direction="column">
          <Typography sx={{fontSize: '12.7px'}}>
            {strings.zho.hoAdmin.dashboard.modalPopup.totalOrder}{' '}
            {approvedTableList?.totalCount}
          </Typography>
          <Typography sx={{fontSize: '12.7px'}}>
            {strings.zho.hoAdmin.dashboard.modalPopup.successfullyApproved}{' '}
            {approvedTableList?.successfullCount}
          </Typography>
        </Stack>
      </Box>
      <Box sx={[styles.modalSection, {padding: '0px'}]}>
        {approvedTableLoading ? (
          approvedTableList?.orders?.length > 0 ? (
            <Box className={tableStyles.container} sx={{padding: '0 0 0 10px'}}>
              <DataGrid
                sx={[styles.errorTable]}
                rows={approvedTableList?.orders}
                disableSelectionOnClick
                columns={columns}
                initialState={{
                  pagination: {
                    pageSize: 10,
                  },
                }}
                getRowId={row => row.orderNumber || Math.random()}
                rowsPerPageOptions={[10, 20, 50, 100]}
              />
            </Box>
          ) : (
            <Typography
              sx={{
                textAlign: 'center',
                padding: '20px',
                fontWeight: 400,
                fontSize: '12.7px',
              }}>
              No record!
            </Typography>
          )
        ) : (
          <Box
            className={tableStyles.loader}
            sx={[
              {
                display: 'flex',
                justifyContent: 'center',
                mt: 3,
              },
            ]}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box
        marginTop={!approvedTableLoading ? '6rem' : '0rem'}
        sx={[styles.modalSection, styles.modalFooter]}>
        <Button
          data-testid="close-errorTable"
          variant="contained"
          sx={{
            fontSize: '12.7px',
            textTransform: 'capitalize',
          }}
          onClick={handleToggleModal}>
          {strings.zho.close}
        </Button>
      </Box>
    </>
  );
};

export default OrderApproveTable;
