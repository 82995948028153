import {createSlice} from '@reduxjs/toolkit';

const initialStateValue = {
  orderReviewPopup: false,
  navbarShow: true,
  currentOrderId: 0,
  stateSelect: null,
  stateHqMulti: [],
  stateMrMulti: [],
  requestPayloadOrder: {
    division: [],
    currentStaffPositionId: 0,
    pageSize: 10,
    pageNumber: 0,
    formNo: '',
    mrStaffIds: [],
    state: '',
    hq: [],
    startDate: '',
    endDate: '',
    tabType: 1,
    type: 1,
  },
};

export const HoOrderState = createSlice({
  name: 'hoOrderStore',
  initialState: {value: initialStateValue},
  reducers: {
    setStateSelect: (state, action) => {
      state.value.stateSelect = action.payload;
    },
    setHqMulti: (state, action) => {
      state.value.stateHqMulti = action.payload;
    },
    setMrMulti: (state, action) => {
      state.value.stateMrMulti = action.payload;
    },
    setOrderReviewPopup: state => {
      state.value.orderReviewPopup = !state.value.orderReviewPopup;
    },

    setCurrentOrderId: (state, action) => {
      state.value.currentOrderId = action.payload;
    },

    updateRequestPayloadOrder: (state, action) => {
      state.value.requestPayloadOrder = {
        ...state.value.requestPayloadOrder,
        ...action.payload,
      };
    },
    clearRequestPayloadOrder: state => {
      const {pageSize, pageNumber} = state.value.requestPayloadOrder;
      state.value.requestPayloadOrder = {
        ...state.value.requestPayloadOrder,
        currentStaffPositionId: 0,
        pageSize: pageSize, // retain the current pageSize
        pageNumber: pageNumber, // retain the current pageNumber
        formNo: '',
        mrStaffIds: [],
        state: '',
        hq: [],
        startDate: '',
        endDate: '',
        division: [],
        tabType: 1,
        type: 1,
      };
    },
    setNavbarShow: (state, action) => {
      state.value.navbarShow = action.payload;
    },
  },
});

export const {
  updateRequestPayloadOrder,
  clearRequestPayloadOrder,
  setStateSelect,
  setHqMulti,
  setMrMulti,
  setNavbarShow,
  setOrderReviewPopup,
  setCurrentOrderId,
} = HoOrderState.actions;

export const hoOrderReducer = HoOrderState.reducer;
